import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { useBreakpoint, withBreakpoints } from 'gatsby-plugin-breakpoints';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import HeroImage from '../components/HeroImage';
import HeroImageText from '../components/HeroImageText';
import ResourceList from '../components/ResourceList';

const NotFoundPage = ({ style }) => {
  const browser = typeof window !== "undefined" && window;

  const breakpoints = useBreakpoint();
  let data = useStaticQuery(graphql`
  query {
    allWpPage(filter: {id: {eq: "cG9zdDo1NTI="}}) {
      edges {
        node {
          errorField {
            header {
              buttonCopy
              buttonDestination
              title
              text
              image {
                sourceUrl
              }
              mobileImage {
                sourceUrl
              }
            }
            resourceSection {
              resourceSectionTitle
              resourceList {
                buttonUrl
                buttonText
                icon {
                  altText
                  title
                  sourceUrl
                }
                text
                title
              }
            }
          }
        }
      }
    }
  }
  `);
  const {
    allWpPage: {
      edges: [
        {
          node: {
            errorField: {
              header: {
                text,
                title,
                buttonCopy,
                buttonDestination,
                image: desktopImage,
                mobileImage,
              } = {},
              resourceSection
            } = {}
          } = {},
        } = {},
      ] = [],
    } = {},
  } = data;

  const image = !breakpoints.sm ? (desktopImage?.sourceUrl || '') :(mobileImage?.sourceUrl || '');
  return (
    browser && (
    <Layout style={style}>
      <Seo title={title} />
      <div>
        {
          image && (
            <HeroImage image={image} >
              <div className="error-container" >
                {
                  !breakpoints.sm && (
                    <div className='carousel-description'>
                      <div className='carousel-description-text'>
                        {
                          title && <h2>{title}</h2>
                        }
                        {
                          text && <p>{text}</p>
                        }
                        {
                          buttonCopy && buttonDestination && <a href={buttonDestination}>{buttonCopy}</a>
                        }
                      </div>
                    </div>
                  )
                }
              </div>
            </HeroImage>
          )
        }
        {
          breakpoints.sm && (
            <HeroImageText>
              <div className='carousel-description'>
                <div className='carousel-description-text'>
                  {
                    title && <h2>{title}</h2>
                  }
                  {
                    text && <p>{text}</p>
                  }
                  {
                    buttonCopy && <a href={buttonDestination}>{buttonCopy}</a>
                  }
                </div>
              </div>
            </HeroImageText>
          )
        }
        {
          resourceSection && resourceSection.length && <ResourceList data={resourceSection} />
        }
      </div>
    </Layout>
    )
  )
}

const style = `
  .page-main {
    margin-top: 0 !important;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1440px;
    padding: 0 4.0875em;
    @media screen and (max-width: 992px) {
      padding: 0 1.0875em;
    }
  }
  .carousel-container {
    min-height: 700px !important;
    justify-content: center !important;
  }
  .error-container {
    z-index: 10;
  }
  .carousel-description {
    padding: 0 4.0875em;
    @media screen and (max-width: 992px) {
      padding: 0;
    }
  }
`

const NotFoundPageStyled = () => (
  <NotFoundPage style={style} />
)

NotFoundPage.propTypes = {
  style: PropTypes.string
};

NotFoundPage.defaultProps = {
  style: ''
};

NotFoundPageStyled.propTypes = {
};

NotFoundPageStyled.defaultProps = {
};

export default withBreakpoints(NotFoundPageStyled);
